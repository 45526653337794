import styled from 'styled-components'
import { Loader, Button } from 'components/common'

export const ClipboardCopy = styled.div`
  margin-left: 8px;
  cursor: pointer;
`

export const BatchId = styled.div`
  display: flex;
  align-items: center;
`

export const Container = styled.div`

`

export const LoaderStyled = styled(Loader)`
  margin: 0 auto;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const ButtonIcon = styled.div`
`

export const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`