import styled from 'styled-components'

export const TokenBalance = styled.div`
  font-size: 14px;
`

export const TokenBalanceValue = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  display: inline-block;
  vertical-align: bottom;
`
