import styled from 'styled-components'
import { Switcher, Input, Select } from 'linkdrop-ui'

export const InputStyled = styled(Input)``

export const SwitcherStyled = styled(Switcher)``

export const SelectStyled = styled(Select)`
  margin-bottom: 12px;
`
